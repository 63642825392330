/**
 * External Dependencies
 */
import 'jquery';
import Slideout from 'slideout';

// var imagesLoaded = require('imagesloaded');

$(document).ready(() => {
  windowLoad();
  mobileMenu();
  scroller();
  animateOnScroll();
  superMenu();
  cycleTitles();
  youtubeEmbed();
  smoothScrollTo();
  Accordion();
  showMoreEvents();
  search();
  addIdToFormInput();
  pcMilerBtnControl();
  readMores();
  buttonOnce();

  var url_pathname = window.location.pathname;
  if (url_pathname == '/trucking-software-reviews/') {
    reviewsMap();
  }
});

function buttonOnce() {
  $('.wpcf7-form').on('submit', function () {
    console.log('submitted');

    $(this).find('button').attr('disabled', true);

    setTimeout(() => {
      $(this).find('button').attr('disabled', false);
    }, '1500');
  });
}

/*
 * Removes loading animation when page load is completed
 */
function windowLoad() {
  // var loader;
  if (document.readyState == 'loading') {
    // loader = requestAnimationFrame(animateLoaderScript);
  }
  $(window).load(function () {
    $('.page-loader').fadeOut('slow');
    $('body').removeClass('preload');
  });

  $('.splash-two-column').imagesLoaded(function () {
    $('.page-loader').fadeOut('slow');
    $('body').removeClass('preload');
  });
}

/*
 * Adds a button to nav links with sub menus, when clicked shows the sub menu
 */
function mobileMenu() {
  var $menu = $('#mobile-menu'),
    $menuButton =
      '<button type="button" class="mobile-nav-sub-menu-button"><i class="fas fa-chevron-down"></i></button>';

  $menu.find('.menu-item-has-children').each(function () {
    $(this).append($menuButton);
  });

  var current = $('.current-menu-ancestor');
  current.addClass('visible');
  current.find('.mobile-nav-sub-menu-button').addClass('open');

  $('.mobile-nav-sub-menu-button').click(function () {
    $(this).parent().toggleClass('visible');
    $(this).parent().siblings().removeClass('visible');
    $(this).toggleClass('open');
    $(this).parent().siblings().find('.open').removeClass('open');
  });

  var btnSidebar = $('.js__slideout-toggle');
  var blockFixed = $('.panel-fixed');
  var blockMain = document.querySelector('#panel');
  var blockSidebar = document.querySelector('#mobile-menu-slide');
  var blockSidebarWidth = 280;

  var slideoutSidebar = new Slideout({
    panel: blockMain,
    menu: blockSidebar,
    padding: blockSidebarWidth,
    tolerance: 70,
    side: 'right', // move sidebar to right
    touch: false,
    duration: 200,
  });

  btnSidebar.on('click', function () {
    console.log('here');
    slideoutSidebar.toggle();
    $('.btn--nav').toggleClass('is-active');
    // $('#mountainsHome').css('position', 'relative');
    window.dispatchEvent(new Event('resize'));
  });

  slideoutSidebar.on('beforeopen', function () {
    blockFixed.addClass('fixed-open');
  });

  slideoutSidebar.on('beforeclose', function () {
    blockFixed.removeClass('fixed-open');
  });

  function checkOpen(eve) {
    if (slideoutSidebar.isOpen()) {
      eve.preventDefault();
      slideoutSidebar.close();
      $('.btn--nav').toggleClass('is-active');
    }
  }

  function addClick() {
    document.querySelector('#panel').addEventListener('click', checkOpen);
  }

  function removeClick() {
    document.querySelector('#panel').removeEventListener('click', checkOpen);
    // $(window).trigger('resize');
  }

  slideoutSidebar.on('open', addClick);
  slideoutSidebar.on('close', removeClick);
}

function readMores() {
  var splashReadMore = $(
    '.splash-two-column__main-content.read-more, .two-column__main-content.read-more, .long-text-content__main-content.read-more'
  );
  splashReadMore.each(function (i, el) {
    var thisContainer = $(el);
    var readMoreButton = thisContainer.next();
    readMoreButton.on('click', function () {
      thisContainer.toggleClass('is-expanded');
    });
  });
}
/*
 * Any on scroll functionality should be placed here so only one window scroll is called
 */
function scroller() {
  // == Change Header on scroll ==
  var header = $('.header-primary');
  var backToTop = $('.btn--back-to-top');

  // ******* SCROLL ************\\
  $(window).on('scroll', function () {
    // == Change Header on scroll ==
    scroll = $(window).scrollTop();
    console.log(scroll);
    // set scroll amount (px)
    if (scroll >= 30) {
      header.addClass('header-secondary'); // if scroll is further than #px change class
      // splashBox.css("z-index", -100);
    } else {
      header.removeClass('header-secondary'); // if not (is at top) change class back
    }

    if ($(window).scrollTop() > 400) {
      backToTop.addClass('show');
    } else {
      backToTop.removeClass('show');
    }
  });

  // == Change Header on scroll ==
  var scroll = scroll;
  if (scroll >= 30) {
    header.addClass('header-secondary'); // if scroll is further than #px change class
  } else {
    header.removeClass('header-secondary'); // if not (is at top) change class back
  }
  if ($(window).scrollTop() > 400) {
    backToTop.addClass('show');
  } else {
    backToTop.removeClass('show');
  }

  backToTop.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '400');
  });
}

/*
 * Adds scroll to animation functionality
 * add class="animation-element" to an element you want to be triggered when scrolled to,
 * then your animation found in animation.less
 */
function animateOnScroll() {
  var $animation_elements = $('.animation-element');
  var $tab_animation_elements = $('.tab-animation-element');
  var $force_in_view = $('.force-in-view');
  var $window = $(window);

  function check_if_in_view() {
    var window_height = $window.height() - 200;
    var window_top_position = $window.scrollTop();
    var window_bottom_position = window_top_position + window_height;

    if ($animation_elements) {
      $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = element_top_position + element_height;

        //check to see if this current container is within viewport
        if (
          element_bottom_position >= window_top_position &&
          element_top_position <= window_bottom_position
        ) {
          $element.addClass('in-view');
        }
      });
    }
    if ($force_in_view) {
      $.each($force_in_view, function () {
        $(this).addClass('in-view');
      });
    }
  }

  $(window).load(function () {
    setTimeout(function () {
      $window.on('scroll resize', check_if_in_view);
      $window.trigger('scroll');
    }, 600);
  });
}

/*
 * Handles Super menu hovering image change
 */
function superMenu() {
  $('.fakeli').prepend(
    '<img id="theImg" src="\\app\\uploads\\Solutions\\axon-software-transportation-management-system-1A.svg" title="Transportation Management" alt="axon-software-tms"/>'
  );

  // get current page name
  var current_page =
    window.location.pathname == '/' ? 'Home' : window.location.pathname;

  // images to use in Solution Menu hover state
  var image_data = {
    'fakeli':
      '\\app\\uploads\\Solutions\\axon-software-transportation-management-system-1A.svg',
    'Transportation-Management':
      '\\app\\uploads\\Solutions\\axon-software-transportation-management-system-1A.svg',
    'Trucking-Software':
      '\\app\\uploads\\Solutions\\axon-software-trucking-1B.svg',
    'Ticket-Software': '\\app\\uploads\\Solutions\\axon-software-ticket-1C.svg',
    'Fleet-Management': '\\app\\uploads\\Solutions\\axon-software-fleet-1D.svg',
    'Trucking-Accounting':
      '\\app\\uploads\\Solutions\\axon-software-accounting-1E.svg',
    'IFTA': '\\app\\uploads\\Solutions\\axon-software-IFTA-1F.svg',
    'Fleet-Maintenance':
      '\\app\\uploads\\Solutions\\axon-software-maintenance-1G.svg',
    'Freight-Hauler':
      '\\app\\uploads\\Solutions\\axon-software-freight-carrier-2A.svg',
    'Oilfield-Trucking':
      '\\app\\uploads\\Solutions\\axon-software-oilfield-trucking-2B.svg',
    'Freight-Broker':
      '\\app\\uploads\\Solutions\\axon-software-freight-broker-2C.svg',
    'Dump-Truck': '\\app\\uploads\\Solutions\\axon-software-dump-truck-2D.svg',
    'Intermodal': '\\app\\uploads\\Solutions\\axon-software-intermodal-2E.svg',
    'Auto-Transport':
      '\\app\\uploads\\Solutions\\axon-software-auto-transport-2F.svg',
    'Specialized':
      '\\app\\uploads\\Solutions\\axon-software-specialized-2G.svg',
  };

  // function to assign image associated with menu on hover for solutions sub-menus
  function default_image(page, class_name) {
    var img_src =
      '\\app\\uploads\\Solutions\\axon-software-transportation-management-system-1A.svg'; // default image src when no hover
    var theImg = $('#theImg');
    theImg.stop();
    theImg.fadeOut(function () {
      switch (page) {
        case '/transportation-management/':
        case 'Transportation-Management':
          class_name = class_name || 'Transportation-Management';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/trucking-software/':
        case 'Trucking-Software':
          class_name = class_name || 'Trucking-Software';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/field-ticket-software/':
        case 'Ticket-Software':
          class_name = class_name || 'Ticket-Software';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/fleet-management-software/':
        case 'Fleet-Management':
          class_name = class_name || 'Fleet-Management';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/trucking-accounting-software/':
        case 'Trucking-Accounting':
          class_name = class_name || 'Trucking-Accounting';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/ifta-software/':
        case 'IFTA':
          class_name = class_name || 'IFTA';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/fleet-maintenance-software/':
        case 'Fleet-Maintenance':
          class_name = class_name || 'Fleet-Maintenance';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/freight-management-software/':
        case 'Freight-Hauler':
          class_name = class_name || 'Freight-Hauler';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/oilfield-software/':
        case 'Oilfield-Trucking':
          class_name = class_name || 'Oilfield-Trucking';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/freight-broker-software/':
        case 'Freight-Broker':
          class_name = class_name || 'Freight-Broker';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/dump-truck-software/':
        case 'Dump-Truck':
          class_name = class_name || 'Dump-Truck';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/intermodal-trucking-software/':
        case 'Intermodal':
          class_name = class_name || 'Intermodal';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/auto-transport-software/':
        case 'Auto-Transport':
          class_name = class_name || 'Auto-Transport';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case '/specialized-trucking-software/':
        case 'Specialized':
          class_name = class_name || 'Specialized';
          img_src = image_data[class_name];
          theImg.attr('src', img_src);
          theImg.attr('title', class_name);
          theImg.attr('alt', 'axon-software-' + class_name);
          break;
        case 'Home':
          img_src = image_data['fakeli'];
          theImg.attr('src', img_src);
        default:
          // $('#theImg').attr("src", img_src);
          break;
      }
    });

    theImg.fadeIn();
    return img_src;
  }

  // used for solutions menu hover state
  default_image(current_page);
  // used for solutions menu hover state
  $('.mega-hover-item').hover(
    function () {
      var theImg = $('#theImg');
      theImg.stop();
      var class_name = $(this)[0].classList[1];
      var menu_name = $(this)[0].innerText;
      default_image(class_name);
    },
    function () {
      if (current_page === 'Home') {
        default_image('Home');
      }
    }
  );

  var headerPrimary = $('.header-primary');
  $('.mega-menu-toggle').hover(
    function () {
      headerPrimary.addClass('is-hovering');
    },
    function () {
      headerPrimary.removeClass('is-hovering');
    }
  );
}

// Subtitle word Cycle ---------------------------------------------------------------
function cycleTitles() {
  animateHeadline($('.word-cycle'));

  var duration = 5500;

  function animateHeadline($word_cycle) {
    $word_cycle.each(function () {
      var wordCycle = $(this);

      //trigger animation
      setTimeout(function () {
        hideWord(wordCycle.find('.is-hidden:first-of-type').eq(0));
      }, duration);
    });
  }

  function hideWord($word) {
    var nextWord = takeNext($word);
    switchWord($word, nextWord);
    setTimeout(function () {
      hideWord(nextWord);
    }, duration);
  }

  function takeNext($word) {
    return !$word.is(':last-child')
      ? $word.next()
      : $word.parent().children().eq(0);
  }

  function switchWord($oldWord, $newWord) {
    $oldWord.removeClass('is-visible').addClass('is-hidden');
    $newWord.removeClass('is-hidden').addClass('is-visible');
  }
}

// Load youtube embed on poster click -------------------------------------------------------
function youtubeEmbed() {
  var video_wrapper = $('.js__youtube-video-place');
  //  Check to see if youtube wrapper exists
  if (video_wrapper.length) {
    // If user clicks on the video wrapper load the video.
    $('.js__youtube-video-place__play-button').on('click', function () {
      /* Dynamically inject the iframe on demand of the user.
             Pull the youtube url from the data attribute on the wrapper element. */
      let videoPlace = $(this).parent('.js__youtube-video-place');
      videoPlace.html(
        '<iframe allowfullscreen frameborder="0" allow="autoplay; encrypted-media" class="embed-responsive-item" src="' +
          videoPlace.data('yt-url') +
          '"></iframe>'
      );
    });
  }
}

// Smooth scroll to -----------------------------------------------------------------------
function smoothScrollTo() {
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top - 0, // Height of header
      },
      500
    );
  });
}

function Accordion() {
  //accordions
  var accordionEl = document.querySelectorAll('.component--accordion');
  if (accordionEl) {
    accordionEl.forEach((el) => {
      var thisAccordion = el;

      var thisAccordItem = thisAccordion.querySelectorAll('.accordion-item');

      thisAccordItem.forEach((item) => {
        var thisAccordTrigger = item.querySelector('button');
        // var rect = thisAccordTrigger.element.getBoundingClientRect()
        //var headerHeight = document.querySelector('header.banner').offsetHeight

        thisAccordTrigger.addEventListener('click', () => {
          var selected = el.querySelector('.is--visible');
          if (!item.classList.contains('is--visible') && selected) {
            selected.classList.remove('is--visible');
          }
          item.classList.toggle('is--visible');

          // scrollbar.scrollIntoView(thisAccordTrigger, { offsetTop: 160 })
          // scrollbar.scrollTo(0, thisAccordTrigger.getBoundingClientRect().top, 300)
        });
      });
    });
  }
}

// Show more events -----------------------------------------------------------------------
function showMoreEvents() {
  var page = 1;
  $('.js__show-more').on('click', function () {
    $('.js__show-more').html('<i class="fas fa-spinner fa-spin"></i> Loading');
    page++;
    $('.js__show-more-results').append(
      $('<div>').load(
        site_url + '/events/page/' + page + '/ #js__show-more-grab',
        function () {
          $('.js__show-more').html('Show more');
        }
      )
    );
    if (page >= max_pages) {
      $(this).hide();
    }
  });
}

/*
 * Activates search function
 */
function search() {
  $('.search-btn').click(function (e) {
    // when nav button is clicked
    $('.search-overlay').fadeIn(300); // toggle the nav
    $('.search-overlay__form-search-input').focus();
    e.stopPropagation();
  });
  $('.js__close-search').click(function () {
    $('.search-overlay').fadeOut();
  });
  $(document).on('keydown', function (e) {
    if (e.keyCode === 27) {
      // ESC
      $('.search-overlay').fadeOut();
    }
  });
}

/*
 * Add the ID attribute to inputs in CF7
 */
function addIdToFormInput() {
  $('form.wpcf7-form input').each(function () {
    var span = $(this).parent('span');
    if (span) {
      var idAttr = span.attr('id');
      $(this).attr('id', idAttr);
      span.attr('id', '');
    }
    var name = $(this).attr('name');
    var type = $(this).attr('type');
    switch (type) {
      case 'radio':
      case 'checkbox':
        name += '-' + $(this).attr('value');
        name = name.replace(/\s+/g, '-').replace(/\?/g, '').toLowerCase(); //replace spaces with dash, remove question mark, and convert to lowercase
    }
    $(this).attr('id', name);
  });
}

/*
 * Select PC miler option based on button click
 */
function pcMilerBtnControl() {
  $('.js__pc-miler-btn').on('click', function () {
    var option = $(this).data('pcselect');
    if (option == 'free') {
      $('#requesttype-free-trial').prop('checked', true);
    } else if (option == 'upgrade') {
      $('#requesttype-upgrade').prop('checked', true);
    }
  });
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Reviews map logic ============================================================================
const google = window.google;

function reviewsMap() {
  $('.map-loading').fadeIn();

  var loc = [];

  var locationsURL = '/wp-json/axon_endpoint/v1/reviews-locations/';

  fetch(locationsURL, {
    mode: 'same-origin',
    credentials: 'same-origin',
    cache: 'no-cache',
  })
    .then((response) => {
      return response.json();
    })
    .then((myJson) => {
      Array.from(myJson).forEach(function (post) {
        loc.push({
          company: post.acf_company,
          address: post.acf_address,
          video_id: post.acf_video_id,
          title: post.acf_title,
          lat: post.acf_map_pin.lat,
          long: post.acf_map_pin.lng,
          review: post.acf_review,
          review_link: post.acf_review_link,
          type: post.acf_type,
        });
      });
      initMap(loc);
    });
}

function initMap(loc) {
  var userLat = parseFloat(52.161828);
  var userLng = parseFloat(-104.654531);

  var map = new google.maps.Map(document.getElementById('js--axon'), {
    zoom: 10,
    minZoom: 3,
    maxZoom: 12,
    center: new google.maps.LatLng(userLat, userLng),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true, // a way to quickly hide all controls
    zoomControl: true,
    scrollwheel: false,
    navigationControl: false,
    mapTypeControl: false,
    scaleControl: false,
    draggable: true,
    styles: [
      {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [
          { hue: '#2c2e33' },
          { saturation: 7 },
          { lightness: 19 },
          { visibility: 'on' },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          { hue: '#ffffff' },
          { saturation: -100 },
          { lightness: 100 },
          { visibility: 'simplified' },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          { hue: '#ffffff' },
          { saturation: -100 },
          { lightness: 100 },
          { visibility: 'off' },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          { hue: '#bbc0c4' },
          { saturation: -93 },
          { lightness: 31 },
          { visibility: 'simplified' },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          { hue: '#bbc0c4' },
          { saturation: -93 },
          { lightness: 31 },
          { visibility: 'on' },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [
          { hue: '#bbc0c4' },
          { saturation: -93 },
          { lightness: -2 },
          { visibility: 'simplified' },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          { hue: '#e9ebed' },
          { saturation: -90 },
          { lightness: -8 },
          { visibility: 'simplified' },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          { hue: '#e9ebed' },
          { saturation: 10 },
          { lightness: 69 },
          { visibility: 'on' },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          { hue: '#e9ebed' },
          { saturation: -78 },
          { lightness: 67 },
          { visibility: 'simplified' },
        ],
      },
    ],
  });
  var infowindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(0, 0),
  });
  var i;
  var marker;
  var markers = new Array();

  for (i = 0; i < loc.length; i++) {
    console.log(loc[i].type)
    var image = '/app/uploads/2019/05/video-icon-bw4.png';
    if (loc[i].type == 'Oilfield Trucking') {
      image = '/app/uploads/2019/05/video-oil-icon-bw.png';
    } else if (loc[i].type == 'Dump Hauler') {
      image = '/app/uploads/2024/05/video-dump-icon-bw.png';
    } else if (loc[i].type == 'Specialized Hauler') {
      image = '/app/uploads/2024/05/video-specialized-icon-bw.png';
    }
    if (parseFloat(loc[i].lat) && parseFloat(loc[i].long)) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          parseFloat(loc[i].lat),
          parseFloat(loc[i].long)
        ),
        map: map,
        icon: image,
      });

      markers.push(marker);

      google.maps.event.addListener(
        marker,
        'click',
        (function (marker, i) {
          return function () {
            var string = '<div class="info--window">';
            string +=
              '<h4 class="text-center"><strong>' +
              loc[i].company +
              '</strong></h4>';
            string += '<p class="text-center">' + loc[i].address + '</p>';
            string += '<div class="info--window--review-container">';
            if (loc[i].video_id.length > 0) {
              string +=
                '<img src="https://img.youtube.com/vi/' +
                loc[i].video_id +
                '/default.jpg" alt="' +
                loc[i].title +
                '">';
            }
            if (loc[i].video_id.length > 0) {
              string +=
                '<p><strong>' +
                loc[i].title +
                '</strong><br>' +
                loc[i].review +
                '</p>';
            } else {
              string +=
                '<p class="text-center"><strong>' +
                loc[i].title +
                '</strong><br>' +
                loc[i].review +
                '</p>';
            }
            string += '</div">';
            string += '</div>';
            string +=
              '<p class="mb-0 text-center"><a href="' +
              loc[i].review_link +
              '">See the full review</a></p>';

            infowindow.setContent(string);
            infowindow.open(map, marker);
          };
        })(marker, i)
      );
    }
  }

  //  Create a new viewpoint bound
  var bounds = new google.maps.LatLngBounds();
  //  Go through each...
  $.each(markers, function (index, marker) {
    bounds.extend(marker.position);
  });
  //  Fit these bounds to the map
  map.fitBounds(bounds);

  /* attach search input element */
  var searchBlock = $('#search-block');
  var address = $('#address');

  var autocomplete = new google.maps.places.Autocomplete(address[0]);
  google.maps.event.addListener(autocomplete, 'place_changed', function () {
    var place = autocomplete.getPlace();

    console.log(place);

    if (!place.geometry) {
      $('#not-found-message').show();
      return;
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(11);

      // marker.setPosition(place.geometry.location);
      // marker.setVisible(true);
      // google.maps.event.trigger( marker, 'mouseup' );
    }
  });

  // select the first autocompletre result on enter
  var pac_input = document.getElementById('address');

  (function pacSelectFirst(input) {
    // store the original event binding function
    var _addEventListener = input.addEventListener
      ? input.addEventListener
      : input.attachEvent;

    function addEventListenerWrapper(type, listener) {
      // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
      // and then trigger the original listener.
      if (type == 'keydown') {
        var orig_listener = listener;
        listener = function (event) {
          var suggestion_selected = $('.pac-item-selected').length > 0;
          if (event.which == 13 && !suggestion_selected) {
            var simulated_downarrow = $.Event('keydown', {
              keyCode: 40,
              which: 40,
            });
            orig_listener.apply(input, [simulated_downarrow]);
          }

          orig_listener.apply(input, [event]);
        };
      }

      _addEventListener.apply(input, [type, listener]);
    }

    input.addEventListener = addEventListenerWrapper;
    input.attachEvent = addEventListenerWrapper;
  })(pac_input);

  /* center map on window resize (basically device rotation) */
  $(window).on('resize', function () {
    if (map && marker) {
      map.setCenter(marker.getPosition());
    }
  });

  $('.map-loading').fadeOut();
}

$('#address').bind('enterKey', function (e) {
  if ($(this).val() == '') {
    reviewsMap();
  }
});
$('#address').keyup(function (e) {
  if (e.keyCode == 13) {
    $(this).trigger('enterKey');
  }
});

// MAT Contest Form - Visa Prize
if (document.querySelector('.visaprize')) {
  // button to close modal
  let btnClose = document.querySelector('.modal-btn--close');
  btnClose &&
    btnClose.addEventListener('click', function () {
      console.log('closed!');
      document
        .querySelector('.modal-received--open')
        .classList.remove('modal-received--open');
    });

  // form submission event
  let wpcf7Elm = document.querySelector('.wpcf7');
  wpcf7Elm &&
    wpcf7Elm.addEventListener(
      'wpcf7mailsent',
      function () {
        document.querySelector('body').classList.add('modal-received--open');
      },
      false
    );
}
